<template>
  <div>
    <!-- dialog -->

    <van-dialog
      :before-close="addSubBefore"
      v-model="addSub"
      show-cancel-button
      class="bankcardDilog"
    >
      <template #title>
        <div>
          <p style="width: 100%; text-align: left">新增下线列表</p>
        </div>
      </template>
      <img src="/new/handI.png" alt="" class="dialogImg" />

      <van-form :submit-on-enter="false" class="formWrap">
        <van-field
          style="border-radius: 10px 10px 0 0"
          v-model="registerForm.username"
          name="账号"
          label="账号"
          placeholder="账号"
          input-align="right"
          :rules="[{ required: true, message: '请填写账号' }]"
        />
        <van-field
          v-model="registerForm.name"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          input-align="right"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          v-model="registerForm.sub_limit"
          type="number"
          name="可创下线数量"
          label="可创下线数量"
          placeholder="下线数量"
          input-align="right"
          :rules="[{ required: true, message: '请填写可创下线数量' }]"
        />
        <van-field
          name="switch"
          :label="'可' + $returnBuySel('进货')"
          input-align="right"
        >
          <template #input>
            <van-switch
              active-color="#3FBBFE"
              inactive-color="#cccccc"
              v-model="registerForm.can_payment"
              size="20"
            />
          </template>
        </van-field>
        <van-field name="switch" label="可提现" input-align="right">
          <template #input>
            <van-switch
              active-color="#3FBBFE"
              inactive-color="#cccccc"
              v-model="registerForm.can_withdraw"
              size="20"
            />
          </template>
        </van-field>
        <van-field
          v-model="registerForm.email"
          type="email"
          name="email"
          label="信箱"
          placeholder="email"
          input-align="right"
        />
        <van-field
          v-model="registerForm.phone"
          type="phone"
          name="电话"
          label="电话"
          placeholder="电话"
          input-align="right"
        />
        <van-field
          v-model="registerForm.qq"
          type="QQ"
          name="QQ"
          label="QQ"
          placeholder="QQ"
          input-align="right"
        />
        <van-field
          style="border-radius: 0 0 10px 10px"
          v-model="registerForm.wechat"
          type="wechat"
          name="微信"
          label="微信"
          placeholder="微信"
          input-align="right"
        />
        <!-- <div style="margin: 16px" class="submitBtn">
          <van-button
            :color="$returnColor().color3"
            style="width: 88px; height: 34px"
            @click="confirmRegister"
            type="info"
            native-type="submit"
            >提交</van-button
          >
        </div> -->
      </van-form>
    </van-dialog>

    <van-dialog
      cancel-button-text="取消"
      confirm-button-text="确认"
      v-model="editRateDialog"
      :title="currentData.name"
      show-cancel-button
      :before-close="editRateBefore"
    >
      <van-field
        label="渠道"
        type="number"
        :rules="[{ required: true, message: '请填写费率' }]"
      >
        <template #input>
          <p class="van-field__label" style="font-size: 14px; margin: 0">
            费率
          </p>
        </template>
        <template #right-icon>
          <p class="van-field__label" style="font-size: 14px; margin: 0">
            开关
          </p>
        </template>
      </van-field>
      <van-field
        v-for="rate in rateList"
        :key="rate.bank_name"
        v-model="rate.rate"
        :name="rate.bank_name"
        :label="rate.name"
        :placeholder="rate.bank_name"
        @input="
          /^(0(\.\d{0,4})?|1(\.0{0,4})?)$/.test(rate.rate)
            ? ''
            : (rate.rate = 0)
        "
        type="number"
        :rules="[{ required: true, message: '请填写费率' }]"
      >
        <template #right-icon>
          <p
            class="van-field__label"
            style="font-size: 14px; display: flex; justify-content: center"
          >
            <van-switch
              :value="rate.enable == 1"
              @change="rate.enable == 1 ? (rate.enable = 0) : (rate.enable = 1)"
              active-color="#009aaa"
              inactive-color="#cccccc"
              size="20"
            />
          </p>
        </template>
      </van-field>
    </van-dialog>
    <van-dialog
      v-model="show"
      :title="currentSub.name"
      show-cancel-button
      :before-close="editSubBefore"
    >
      <van-field
        v-model="currentSub.sub_limit"
        name="下线配额"
        label="下线配额"
        placeholder="下线配额"
        input-align="right"
        type="number"
        :rules="[{ required: true, message: '请填写下线配额' }]"
      />
    </van-dialog>
    <van-sticky>
      <van-nav-bar
        title="下线列表"
        @click-left="$router.push($cookie.get('prevPage') || '/')"
      >
        <template #left>
          <van-icon name="arrow-left" />
        </template>
        <template #right>
          <div
            :style="{ color: $returnColor().color3 }"
            class="addAcc"
            @click="addSub = true"
          >
            <van-icon class="plus" name="plus" />创建下线
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>
    <div class="wrap">
      <div class="contentWrap">
        <div class="cardWrap">
          <div
            class="cardBox"
            v-for="report in reportData"
            :key="report.member_id"
          >
            <div class="cardTitle">
              <h3 @click="showLower(report)">
                {{ report.name }}
              </h3>
              <van-icon
                @click.stop="onContextmenu(report)"
                name="ellipsis"
                style="
                  position: absolute;
                  right: 20px;
                  font-size: 20px;
                  font-weight: bold;
                  transform: rotate(90deg);
                "
              />
            </div>
            <div class="content">
              <van-cell-group style="width: 100%">
                <van-cell title="下线配额" :value="report.sub_limit" />
                <van-cell is-link title="费率">
                  <template #right-icon>
                    <van-button
                      type="info"
                      size="small"
                      style="margin-left: 25px; margin-bottom: 10px"
                      @click="editRate(report)"
                      >編輯</van-button
                    >
                  </template>
                </van-cell>
              </van-cell-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot />
  </div>
</template>
<script>
import { mapState } from "vuex";
import foot from "../../../components/footer";
export default {
  components: { foot },
  data() {
    return {
      except: {},
      reportData: [],
      nowPage: 1,
      currentMemberId: "",
      currentReport: {},
      inSearch: false,
      paginate: {},
      show: false,
      show2: false,
      currentSub: {},
      value: "",
      selectSub: {},
      selectSubShow: false,
      subList: [],
      currentData: {},
      editRateDialog: false,
      rateList: [],
      saveRateList: [],
      registerForm: {},
      addSub: false,
    };
  },
  computed: {
    ...mapState(["selfData"]),
  },
  watch: {
    // selectSub(){
    // }
  },
  methods: {
    addSubBefore(method, done) {
      if (method == "confirm") {
        this.registerForm.status = 1;
        let sendData = JSON.parse(JSON.stringify(this.registerForm));
        sendData.can_withdraw
          ? (sendData.can_withdraw = 1)
          : (sendData.can_withdraw = 0);
        sendData.can_payment
          ? (sendData.can_payment = 1)
          : (sendData.can_payment = 0);
        this.$http.post(`/mobile/subs`, sendData).then((res) => {
          if (res.data.code == 0) {
            done();
            this.registerForm = {
              can_withdraw: 1,
              can_payment: 1,
            };
            if (this.$store.state.selfData.hide_team != 1) {
              this.editRate(res.data.data);
            }
            this.currentData = res.data.data;
            this.show2 = true;
          } else {
            done(false);
          }
        });
      } else {
        done();
      }
    },
    editRateBefore(method, done) {
      if (method == "confirm") {
        let sendData = [];
        this.rateList.forEach((e) => {
          let same = false;
          this.saveRateList.forEach((save) => {
            if (this.$deepEqual(e, save)) same = true;
          });
          if (!same) {
            sendData.push(e);
          }
        });
        if (!sendData.length) {
          done();
          return;
        }
        this.$http
          .post(`/mobile/subs/${this.currentData.id}/rate`, {
            update: sendData,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$toast.success("成功");
              done();
            } else {
              done(false);
            }
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    editRate(data) {
      this.currentData = JSON.parse(JSON.stringify(data));
      this.$http.get(`/mobile/subs/${data.id}/rate`).then((res) => {
        if (res.data.code == 0) {
          let resData = res.data.data;
          if (Array.isArray(resData)) {
            this.rateList = res.data.data;
          } else {
            try {
              this.rateList = Object.values(res.data.data);
            } catch {
              console.error("nini");
            }
          }
          this.saveRateList = JSON.parse(JSON.stringify(this.rateList));
          this.editRateDialog = true;
        }
      });
    },
    onContextmenu(data) {
      if (data.locked == 1) {
        this.$contextmenu({
          items: [
            {
              label: "编辑",
              onClick: () => {
                this.editSub(data);
              },
            },
            {
              label: "停权(已停权)",
              disabled: true,
            },
            {
              label: "一键停权(已停权)",
              disabled: true,
            },
          ],
          event,
          customClass: "class-a",
          zIndex: 99999,
          minWidth: 230,
        });
        return false;
      }
      this.$contextmenu({
        items: [
          {
            label: "编辑",
            onClick: () => {
              this.editSub(data);
            },
          },
          {
            label: "停权",
            onClick: () => {
              this.lockOne(data);
            },
          },
          {
            label: "一键停权(含下线)",
            onClick: () => {
              this.lockAll(data);
            },
          },
        ],
        event,
        customClass: "class-a",
        zIndex: 99999,
        minWidth: 230,
      });
      return false;
    },
    lockOne(data) {
      this.$dialog
        .alert({
          message: `确定停权<br/>${data.name}?`,
          showCancelButton: true,
          closeOnPopstate: false,
        })
        .then((e) => {
          // if(e==)
          if (e == "confirm") {
            this.$http
              .put(`/mobile/subs/${data.id}`, {
                locked: 1,
              })
              .then((res) => {
                if (res.data.code == 0) {
                  let currentData = JSON.parse(JSON.stringify(this.reportData));
                  currentData.forEach((e, index) => {
                    if (data.id == e.id) {
                      currentData[index].locked = 1;
                    }
                  });
                  this.reportData = currentData;
                  this.$toast.success("成功");
                }
              });
          }
        });
    },
    lockAll(data) {
      this.$dialog
        .alert({
          message: `确定停权<br/>${data.name}(含下线)?`,
          showCancelButton: true,
          closeOnPopstate: false,
        })
        .then((e) => {
          if (e == "confirm") {
            this.$http
              .post(`/mobile/subs/${data.id}/locked`, {
                locked: 1,
              })
              .then((res) => {
                if (res.data.code == 0) {
                  let currentData = JSON.parse(JSON.stringify(this.reportData));
                  currentData.forEach((e, index) => {
                    if (data.id == e.id) {
                      currentData[index].locked = 1;
                    }
                  });
                  this.reportData = currentData;
                  this.$toast.success("成功");
                }
              });
          }
        });
    },
    onConfirm(e) {
      this.selectSub = e;
      this.selectSubShow = false;
    },
    searchData() {
      this.inSearch = true;
      this.reportData = [];
      this.nowPage = 1;
      this.getData();
    },
    editSub(report) {
      this.currentSub = JSON.parse(JSON.stringify(report));
      this.show = true;
    },
    editSubBefore(method, done) {
      if (method == "confirm") {
        this.$http
          .put(`/mobile/subs/${this.currentSub.id}`, {
            sub_limit: this.currentSub.sub_limit,
            rate_bank: this.currentSub.rate_bank,
          })
          .then((res) => {
            if (res.data.code == 0) {
              done();
              let currentData = JSON.parse(JSON.stringify(this.reportData));
              currentData.forEach((e, index) => {
                if (res.data.data.id == e.id) {
                  currentData[index] = res.data.data;
                }
              });
              this.reportData = currentData;
              this.$successMeg();
            } else {
              done(false);
            }
          });
      } else {
        done();
      }
    },
    getData() {
      let sendData = { page: this.nowPage, paginate: 1 };
      if (this.value) {
        sendData.search = this.value;
      }
      this.$http
        .get(`/mobile/subs`, {
          params: sendData,
        })
        .then((res) => {
          if (res.data.code == 0) {
            let currentData = JSON.parse(JSON.stringify(this.reportData));
            res.data.data.forEach((d) => {
              let have = false;
              currentData.forEach((e, index) => {
                if (d.id == e.id) {
                  have = true;
                  currentData[index] = d;
                }
              });
              if (!have) {
                currentData.push(d);
              }
            });
            this.reportData = currentData;
            this.next_page = res.data.next_page;
            this.inSearch = false;
          }
        });
    },
    scrollPage() {
      if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
        if (this.next_page) {
          this.nowPage += 1;
          this.getData();
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollPage);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollPage);
  },
  created() {
    // this.$http.get(`/mobile/subs`).then((res) => {
    //   if (res.data.code == 0) {
    //     let subList = [];
    //     subList.push({ id: false, text: "全部" });
    //     Object.keys(res.data.data).forEach((e) => {
    //       subList.push({ member_id: e, id: e, text: res.data.data[e] });
    //     });
    //     this.subList = subList;
    //   }
    // });
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  background-color: #f1f5fe;
  min-height: 100vh;
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding-bottom: 110px;
  padding-top: 0;
}
.contentWrap {
  max-width: 355px;
  width: 95%;
}
.selectWrap {
  display: flex;
  margin-bottom: 14px;
  .dateWrap {
    width: 60%;
    margin-right: 7.5px;
  }
  .iosSelect {
    width: 40%;
    margin-left: 7.5px;
  }
}
.cardWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .cardBox {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 15px;
      width: 100%;
      .box {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .balance {
          font-weight: bold;
        }
        p {
          margin: 0;
          padding: 5px;
        }
      }
    }
  }
  b {
    width: 140px;
    height: 0;
  }
}
.cardTitle {
  width: 100%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px 0 20px;
  position: relative;
  .editBtn {
    position: absolute;
    right: 40px;
  }
  h3 {
    margin: 0;
  }
}
.addAcc {
  display: flex;
  align-items: center;
  // color: #009aaa;
  font-size: 14px;
  .plus {
    color: #009aaa !important;
    font-weight: bold;
    font-size: 14px;
    margin-right: 5px;
    //
  }
}

.dialogImg {
  position: absolute;
  width: 109px;
  top: -48px;
  right: 8px;
  z-index: -1;
}
</style>
